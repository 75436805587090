import { default as headings } from './Headings'
import { default as figure } from './Figure'
import { default as figcaption } from './Figcaption'
import { default as code } from './CodeBlock'
import { default as CheckPriceButton } from './AmzButton'
import { default as BuyBox } from './BuyBox'

export default {
  BuyBox,
  CheckPriceButton,
  figure,
  figcaption,
  pre: props => props.children,
  code,
  ...headings
}
