import React from 'react'
import { css } from 'theme-ui'
import { Link } from 'theme-ui'

const BuyBox = props => {
  const affLink = `${props.link}?tag=reviewrune-20`
  return (
    <div style={{ textAlign: 'center', paddingBottom: '2rem' }}>
      <Link
        {...props}
        target='_blank'
        rel='nofollow noopener sponsored'
        href={affLink}
        css={css(t =>
          Object.assign(t.styles.checkPriceButton, t.styles.buyBox)
        )}
        onClick={() =>
          typeof window !== 'undefined' && window.gtag &&
          window.gtag('event', 'click', {
            event_category: 'Amazon Link',
            event_label: 'Inline Buy Box',
            transport_type: 'beacon'
          })
        }
      >
        Check Price on Amazon
      </Link>
    </div>
  )
}

export default BuyBox
