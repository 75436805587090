import React from 'react'
import { css } from 'theme-ui'
import { Link } from 'theme-ui'

const CheckPriceButton = props => {
  const affLink = `${props.link}?tag=reviewrune-20`
  return (
    <Link
      {...props}
      target='_blank'
      rel='nofollow noopener'
      href={affLink}
      css={css(t => t.styles.checkPriceButton)}
      onClick={() =>
        typeof window !== 'undefined' && window.gtag &&
        window.gtag('event', 'click', {
          event_category: 'Amazon Link',
          event_label: 'Check Price Table',
          transport_type: 'beacon'
        })
      }
    >
      Check Price Now
    </Link>
  )
}

export default CheckPriceButton
